import React, { useState } from 'react';
import { db } from '../Firebase';
import { collection, query, where, getDocs } from "firebase/firestore";

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);

    const setAuthenticatedUser = (userData) => {
        localStorage.setItem('authenticatedUser', JSON.stringify(userData));
    };

    
    const handleSubmit = async (e) => {
        e.preventDefault();
        const q = query(collection(db, 'superadmin'), where('email', '==', email));
        const querySnapshot = await getDocs(q);
console.log(email)
        if (querySnapshot.empty) {
            console.log('No email');
            setError(true);
            return; 
        }

        const userDoc = querySnapshot.docs.find(doc => doc.data().password === password);
        if (userDoc) {
            const userData = userDoc.data();
            setAuthenticatedUser(userData);
            window.location.href = '/'; 
        } else {
            setError(true);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center">
            <form className="bg-white p-8 border-2 rounded shadow-md w-full sm:w-96" onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label htmlFor="email" className="block text-gray-800 text-sm font-semibold mb-2">Email</label>
                    <input type="email" id="email" className="w-full p-2 border border-gray-300 rounded" value={email} onChange={e => setEmail(e.target.value)} />
                </div>
                <div className="mb-4">
                    <label htmlFor="password" className="block text-gray-800 text-sm font-semibold mb-2">Password</label>
                    <input type="password" id="password" className="w-full p-2 border border-gray-300 rounded" value={password} onChange={e => setPassword(e.target.value)} />
                </div>
                <button type="submit" className="w-full bg-gray-800 text-white p-2 rounded hover:bg-gray-700 focus:outline-none focus:shadow-outline">Login</button>
                {error && <span className="text-red-500 font-semibold mt-2">Invalid username or password</span>}
            </form>
        </div>
    );
}

export default Login;
