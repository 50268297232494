import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import Login from './pages/Login';
import Home from './pages/Home';
import { db } from './Firebase';
import { collection, query, where, getDocs } from "firebase/firestore";
import AddCandidate from './components/AddCandidate';
import EditLead from './pages/EditLead';
import JoinedLeads from './pages/JoinedLeads';
import NegativeLeads from './pages/NegativeLeads';

const App = () => {
    const [authenticatedUser, setAuthenticatedUser] = useState(null);
    const [loading, setLoading] = useState(true); // Add loading state

    useEffect(() => {
        const checkAuthenticatedUser = async () => {
            try {
                const storedUserData = JSON.parse(localStorage.getItem('authenticatedUser'));
                if (storedUserData) {
                    const q = query(collection(db, 'superadmin'), where('email', '==', storedUserData.email));
                    const querySnapshot = await getDocs(q);
                    if (!querySnapshot.empty) {
                        const userDoc = querySnapshot.docs.find(doc => doc.data().password === storedUserData.password);
                        if (userDoc) {
                            setAuthenticatedUser(storedUserData);
                        }
                    }
                }
                setLoading(false); // Update loading state
            } catch (error) {
                console.error("Error occurred while checking authenticated user:", error);
                setLoading(false); // Update loading state in case of error
            }
        };

        checkAuthenticatedUser();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={authenticatedUser ? <Home /> : <Navigate to="/login" />} />
                <Route path="/add" element={authenticatedUser ? <AddCandidate /> : <Navigate to="/login" />} />
                <Route path="/joined" element={authenticatedUser ? <JoinedLeads /> : <Navigate to="/login" />} />
                <Route path="/negative" element={authenticatedUser ? <NegativeLeads /> : <Navigate to="/login" />} />
                <Route path="/login" element={!authenticatedUser ? <Login setAuthenticatedUser={setAuthenticatedUser} /> : <Navigate to="/" />} />
                <Route path="/edit/:leadId" element={authenticatedUser ? <EditLead /> : <Navigate to="/login" />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
