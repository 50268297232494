import React, { useState } from 'react';

const StatusDropdown = ({ docId, currentStatus, handleStatusUpdate }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(currentStatus);

  const getStatusColor = (status) => {
    switch (status) {
        case 'Positive':
            return 'bg-green-500 text-white';
        case 'Pending':
            return 'bg-yellow-500 text-white';
        case 'Low':
            return 'bg-blue-500 text-white';
        case 'Hold':
            return 'bg-gray-500 text-white';
        case 'Negative':
            return 'bg-red-500 text-white';
        case 'Joined':
            return 'bg-purple-500 text-white';
        default:
            return 'bg-gray-300 text-white';
    }
};


  const options = [
    'Positive',
    'Pending',
    'Low',
    'Hold',
    'Negative',
    'Joined',
  ];

  const handleOptionClick = (newStatus) => {
    setSelectedStatus(newStatus);
    handleStatusUpdate(newStatus);
    setIsOpen(false);
  };

  return (
    <div className="relative inline-block text-left ">
      <div>
        <button
          type="button"
          className={`w-full rounded px-1 ${getStatusColor(selectedStatus)}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          {selectedStatus}
        </button>
      </div>

      {isOpen && (
        <div className="origin-top-right absolute right-0  z-10 mt-2 w-20 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {options.map((option) => (
              <button
                key={option}
                onClick={() => handleOptionClick(option)}
                className={`block px-4 py-2 text-sm text-left hover:bg-gray-100 w-full`}
              >
                {option}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default StatusDropdown;
